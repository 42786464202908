import React from 'react';
import './App.scss';
import axios from 'axios';


function App() {
  
  const [location, setLocation] = React.useState<string>('');
  const [suits, setSuits] = React.useState<number[]>([]);

  function addSuit(suit:number) {
    let updatedSuits = suits;
    updatedSuits.push(suit);
    setSuits([... updatedSuits]);
  }

  React.useEffect(() => {
    if (suits.length > 0) {
      selectCard();
    }
      
  }, [suits]);

  function getSuitList():string {
    let cut = suits.slice(-12);
    let submission = '';
    cut.forEach(number => submission = submission + String(number));
    return submission;
  }


function selectCard() {
  axios({
    "method": "POST",
    "url": `${process.env.REACT_APP_SERVICE_BASEPATH}/the-black-rabbit/select`,
    "headers": {
        'content-type': 'application/json'
    },
    "data": getSuitList()

})
    .then((response) => {
      let serviceResponse = response.data;
      if (serviceResponse) {
        setLocation(serviceResponse);
      }
        
        
    })
    .catch((error) => {
        console.log(error);
        
    });
}

React.useEffect(() => {
  if(location.length > 0) {
    window.location.href = location;
  }
    
}, [location]);
  return (
    <div className="App">
      <header>
      <img src='/the-black-rabbit.jpg' />

      <div className="sub-title">
        <div className="block">Purveyor of Lost Books</div>
      
      </div>
      </header>
      <div className="letter">
        <p>My dearest patrons,</p>
        <p>It brings me great regret to inform you all that my shop will be closed until further notice.</p>
        <p>I pray that I will be able to see you all again in the new year.</p>
        <p>Best regards,</p>
        <p>-The Black Rabbit</p>
      </div>
      <a href="https://twitter.com/TheB1ackRabbit">Follow The Black Rabbit</a>
      <footer>
        <div className='suits'>
        <img src="/suits.png" />
        <div className='suit-overlay'>
          <div onClick={() => addSuit(1)}></div>
          <div onClick={() => addSuit(2)}></div>
          <div onClick={() => addSuit(3)}></div>
          <div onClick={() => addSuit(4)}></div>
        </div>
        </div>
        <div className="the-keepers">
          <span>an invitation from the keepers</span>
          <a href="https://discord.gg/xybcszGygW">
        <svg height="50" width="50" id="canvas">
          <circle cx="25" cy="15" r="14" style={{stroke: '#000', fill: 'transparent', strokeWidth: 2}}/>
          <polygon points="0,0 50,0 25.0,43.3" style={{fill: 'transparent', stroke: '#000', strokeWidth: 2}}></polygon>
          </svg></a>
        </div>
      </footer>
    </div>
  );
}

export default App;
